<!--
 * @Description: 
 * @Author: huacong
 * @Date: 2021-04-01 17:26:19
 * @LastEditTime: 2021-07-20 15:25:15
 * @LastEditors: huacong
-->
<template>
  <!-- 下载记录 -->
  <div>
    <section>
      <el-row class="talentIndex" :gutter="16">
        <el-col :span="8">
          <talentInfo></talentInfo>
        </el-col>
        <el-col :span="16">
          <div class="info-card">
            <el-form :inline="true" class="demo-form-inline">
              <el-form-item label="身份">
                <el-select v-model="comType">
                  <el-option label="顾问猎头" value="顾问猎头"></el-option>
                  <el-option label="顾问猎头1" value="顾问猎头1"></el-option>
                </el-select>
                <el-form-item style="margin-left: 16px">
                  <el-button type="primary" class="radius-25">搜索</el-button>
                </el-form-item>
              </el-form-item>
            </el-form>
            <el-table :data="comTable" style="width: 100%">
              <el-table-column prop="id" label="序号" width="120">
              </el-table-column>
              <el-table-column prop="title" label="查看的公司">
              </el-table-column>
              <el-table-column prop="send_time" label="身份" width="200">
              </el-table-column>
              <el-table-column prop="send_time" label="查看时间" width="200">
              </el-table-column>
              <div slot="empty" class="empty">
                <div>
                  <img :src="noDate" />
                </div>
                <span>暂无数据</span>
              </div>
            </el-table>
            <div class="pageBox">
              <el-pagination
                background
                hide-on-single-page
                @size-change="SizeChange"
                @current-change="CurrentChange"
                :current-page="currentPage"
                :page-sizes="[10, 20, 30, 40]"
                :page-size="pagesize"
                layout="total, sizes, prev, pager, next, jumper"
                :total="tableTotal"
              >
              </el-pagination>
            </div>
          </div>
        </el-col>
      </el-row>
    </section>
  </div>
</template>
<script>
import talentInfo from "@/view/talentPage/talentInfo.vue";
export default {
  data() {
    return {
      noDate: require("@/assets/img/noDate.png"),
      activeName: "first",
      currentPage: 1,
      pagesize: 10,
      tableTotal: 0,
      comTable: [],
      comType: "",
    };
  },
  components: {
    talentInfo,
  },
  methods: {
    SizeChange(data) {
      this.pagesize = data;
      this.currentPage = 1;
    },
    CurrentChange(data) {
      this.currentPage = data;
    },
  },
};
</script>
<style scope>
.talentIndex {
  padding-top: 24px;
}
</style>
